import React from 'react';
import styled from '@emotion/styled';
import { WebAuth } from 'auth0-js';

import Row from '../../templates/Row';
import Column from '../../templates/Column';
import { Config } from '../../../utils/config';

const Content = styled.div`
    width: 100%;
    padding: 40px 0;
    position: relative;
    background-color: rgba(0, 0, 0, 0.04);
`;

const Price = styled.ul`
    list-style-type: none;
    border: 1px solid #b2ebf2;
    margin: 0 10px;
    padding: 0;
    max-width: 300px;
`;

const PriceHeight = styled(Price)`
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.4);
    
    @media (max-width: 768px) {
        margin: 10px auto;
    }
`;

const PriceFree = styled(Price)`
    margin-left: auto;
    
    @media (max-width: 768px) {
        margin: 10px auto;
    }
`;

const Element = styled.li`
    padding: 20px;
    text-align: center;
`;

const ElementFree = styled(Element)`
    border-bottom: 1px solid #b2dfdb;
`;

const ElementHeight = styled(Element)`
    border-bottom: 1px solid #b2ebf2;
`;

const TitleFree = styled(ElementFree)`
    background-color: #00695c;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    min-height: 52px;
    padding-top: 30px;
    padding-bottom: 10px;
`;

const TitleHeight = styled(ElementHeight)`
    background-color: #00838f;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
`;

const BetaInfo = styled.div`
    font-size: 0.8em;
    font-weight: normal;
`;

const ElementColorFree = styled(ElementFree)`
    background-color: #b2dfdb;
    min-height: 77px;
    padding-top: 40px;
    padding-bottom: 0;
`;

const ElementColorHeight = styled(ElementHeight)`
    background-color: #b2ebf2;
`;

const ButtonFree = styled.button`
    border: 1px solid #00695c;
    border-radius: 5px;
    background-color: #00695c;
    color: white;
    padding: 14px 28px;
    margin: auto;
    cursor: pointer;
    min-width: 180px;
    font-size: 18px;
    
    &:hover, &:active, &:focus {
        background-color: white;
        color: #00838f;
        outline: none;
    }
`;

const ButtonHeight = styled.button`
    border: 1px solid #00838f;
    border-radius: 5px;
    background-color: #00838f;
    color: white;
    padding: 14px 28px;
    margin: auto;
    cursor: pointer;
    min-width: 180px;
    font-size: 18px;
    
    &:hover, &:active, &:focus {
        background-color: white;
        color: #00838f;
        outline: none;
    }
`;

interface PricingProps {
    readonly auth0: WebAuth;
}

const Pricing: any = (props: PricingProps) => {
    const {
        auth0
    } = props;

    return (
        <Content>
            <Row>
                <Column nbCol={2}>
                    <PriceFree>
                        <TitleFree>Gratuit</TitleFree>
                        <ElementColorFree><b>0.00 €</b> / mois<br/>ou <b>0.00 €</b> / an</ElementColorFree>
                        <ElementFree>1 Collection</ElementFree>
                        <ElementFree>0 Modèle / Collection</ElementFree>
                        <ElementFree>&infin; Objets</ElementFree>
                        <ElementFree>1 Tag / Objet</ElementFree>
                        <ElementFree>0 Champ personnalisé</ElementFree>
                        <ElementColorFree>
                            <ButtonFree onClick={() => {
                                document.location.href = Config.signUpUrl;
                            }}>
                                Créer un compte
                            </ButtonFree>
                        </ElementColorFree>
                    </PriceFree>
                </Column>
                <Column nbCol={2}>
                    <PriceHeight>
                        <TitleHeight>Premium<BetaInfo>Beta test gratuite</BetaInfo></TitleHeight>
                        <ElementColorHeight><del><b>2.99 €</b></del> / mois<br/>ou <del><b>29.99 €</b></del> / an<br/><b>0.00 €</b> / mois<br/>ou <b>0.00 €</b> / an</ElementColorHeight>
                        <ElementFree>&infin; Collections</ElementFree>
                        <ElementFree>1 Modèle / Collection</ElementFree>
                        <ElementFree>&infin; Objets</ElementFree>
                        <ElementFree>&infin; Tags / Objet</ElementFree>
                        <ElementFree>&infin; Champs personnalisés</ElementFree>
                        {false && <ElementColorHeight>
                            <ButtonHeight>
                                Créer un compte
                            </ButtonHeight>
                        </ElementColorHeight>}
                    </PriceHeight>
                </Column>
            </Row>
        </Content>
    );
}

export default Pricing;