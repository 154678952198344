import React from 'react';
import styled from '@emotion/styled';

import Center from '../../templates/Center';
import { Config } from '../../../utils/config';

const Content = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: url("/images/main-image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    background-attachment: fixed;
`;

const BackgroundColor = styled.div`
    background-color: rgba(255,255,255,0.8);
    width: 100%;
    height: 100vh;
    position: relative;
`;

const TitleArea = styled.div`
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #00838f;
    text-align: center;
`;

const Title = styled.h1`
    font-size: 3em;

    @media (max-width: 1200px) {
        font-size: 2.5em;
    }
`;

const Subtitle = styled.h2`
    font-weight: normal;
    font-size: 2em;

    @media (max-width: 1200px) {
        font-size: 1.5em;
    }
`;

const ButtonArea = styled.div`
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
`;

const ButtonActive = styled.button`
    border: 1px solid #00838f;
    border-radius: 5px;
    background-color: #00838f;
    color: white;
    padding: 14px 28px;
    margin: 10px 20px;
    cursor: pointer;
    min-width: 180px;
    font-size: 18px;
    
    &:hover, &:active, &:focus {
        background-color: white;
        color: #00838f;
        outline: none;
    }
`;

const Button = styled.button`
    border: 1px solid #767676;
    border-radius: 5px;
    background-color: white;
    color: #111111;
    padding: 14px 28px;
    margin: 10px 20px;
    cursor: pointer;
    min-width: 180px;
    font-size: 18px;
    
    &:hover, &:active, &:focus {
        background-color: #b2ebf2;
        border: 1px solid #b2ebf2;
        outline: none;
    }
`;

interface MainProps {
}

const Main: any = (props: MainProps) => {
    const {} = props;
    console.log(Config)

    return (
        <Content>
            <BackgroundColor>
                <Center>
                    <TitleArea>
                        <Title>
                            Bienvenue sur Collectoogo (Beta)
                        </Title>
                        <Subtitle>
                            Collectoogo, votre site de gestion d'objets à collectionner. Facile et personnalisable, il est adapté à toute forme de collection.<br/><br/>
                        </Subtitle>
                    </TitleArea>
                    <ButtonArea>
                        <ButtonActive onClick={() => {
                            document.location.href = Config.signUpUrl;
                        }}>
                            Créer un compte
                        </ButtonActive>
                        <Button onClick={() => {
                            document.location.href = Config.signInUrl;
                        }}>
                            Se connecter
                        </Button>
                    </ButtonArea>
                </Center>
            </BackgroundColor>
        </Content>
    );
}

export default Main;