import React from 'react';
import styled from '@emotion/styled';

const Content = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, -50%);
`;

interface CenterProps {
    children: React.ReactNode;
}

const Center: any = (props: CenterProps) => {
    return (
        <Content>
            {props.children}
        </Content>
    );
}

export default Center;