import React from 'react';
import styled from '@emotion/styled';

const Content = styled.div`
    width: 100%;
    padding: 40px 0;
    position: relative;
    background-color: #00838f;
    color: white;
`;

const TitleArea = styled.div`
    display: block;
    padding: 20px 0;
    text-align: center;
`;

const Title = styled.h2`
    
`;

const Description = styled.div`
    display: block;
    padding: 20px 0 20px 20px;
    font-size: 18px;
    margin: auto;
    max-width: 800px;
    text-align: center;
`;

interface WhatIsThatProps {

}

const WhatIsThat: any = (props: WhatIsThatProps) => {
    return (
        <Content>
            <TitleArea>
                <Title>
                    Qu'est ce que c'est ?
                </Title>
            </TitleArea>
            <Description>
                Collectoogo est une application web, vous permettant de saisir, de répertorier et de gérer vos collections d'objets n'importe où et quand vous le voulez.
            </Description>
        </Content>
    );
}

export default WhatIsThat;