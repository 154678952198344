import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Menu from '../components/menu/Menu';
import Main from '../components/contents/index/Main'
import WhatIsThat from '../components/contents/index/WhatIsThat';
import Infos from '../components/contents/index/Infos';
import Pricing from '../components/contents/index/Pricing';
import Footer from '../components/footer/Footer';

export const query = graphql`
    query {
        allItemsJson {
            __typename
            edges {
                __typename
                node {
                    __typename
                    text
                    linkTo
                }
            }
        }
    }
`;

const Home: any = ({data}: any) => {

    return (
        <>
            <Helmet>
                <title>Collectoogo - Accueil</title>
                <meta name="description" content="Le site pour gérer toutes vos collections d'objets, de manière simple et personnalisé." />
                <meta property="og:url" content="https://collectoogo.fr" />
                <meta property="og:type" content="siteweb" />
                <meta property="og:title" content="Collectoogo - Accueil" />
                <meta property="og:description" content="Le site pour gérer toutes vos collections d'objets, de manière simple et personnalisé." />
                <meta property="og:image" content="https://collectoogo.fr/images/og-collectoogo.jpg" />
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "url": "https://collectoogo.fr",
                            "logo": "https://collectoogo.fr/images/logo-collectoogo-white.svg"
                        }
                    `}
                </script>
            </Helmet>
            <Menu items={data.allItemsJson.edges}>
                <Main/>
                <WhatIsThat/>
                <Infos/>
                <Pricing/>
                <Footer/>
            </Menu>
        </>
    );
}

export default Home;