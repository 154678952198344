import React from 'react';
import styled from '@emotion/styled';

import Row from '../../templates/Row';
import Column from '../../templates/Column';

const Content = styled.div`
    width: 100%;
    padding: 40px 0;
    position: relative;
`;

const Info = styled.div`
    text-align: center;
    padding: 20px 10px;
    color: #00838f;
    display: block;
`;

const Image = styled.img`
    max-width: 250px;
`;

const Title = styled.h2`
    
`;

const Description = styled.div`
    display: block;
    text-align: center;
    margin: auto;
    max-width: 300px;
`;

interface InfosProps {

}

const Infos: any = (props: InfosProps) => {
    return (
        <Content>
            <Row>
                <Column nbCol={3}>
                    <Info>
                        <Image alt="info sur l'utilisation simple" src="/images/easy.svg"/>
                        <Title>
                            Simple d'utilisation
                        </Title>
                        <Description>
                            Des outils modernes sont à votre disposition pour vous simplifier la vie.
                        </Description>
                    </Info>
                </Column>
                <Column nbCol={3}>
                    <Info>
                        <Image alt="info sur l'adaptabilité du site" src="/images/responsive.svg"/>
                        <Title>
                            Responsive
                        </Title>
                        <Description>
                            S'adapte à n'importe quel écran : <b>mobile</b>, <b>tablette</b> et <b>ordinateur</b>.
                        </Description>
                    </Info>
                </Column>
                <Column nbCol={3}>
                    <Info>
                        <Image alt="info sur la personnalisation des champs" src="/images/custom.svg"/>
                        <Title>
                            Personnalisable
                        </Title>
                        <Description>
                            Les champs de saisie : <b>nom</b>, <b>description</b>, <b>image</b> et <b>tags</b> vous sont proposés par défaut. Il est également possible d'en ajouter d'autre, comme bon vous semble.
                        </Description>
                    </Info>
                </Column>
                <Column nbCol={3}>
                    <Info>
                        <Image alt="info sur les modèles par collection" src="/images/template.svg"/>
                        <Title>
                            Un modèle par collection
                        </Title>
                        <Description>
                            Pour plus d'efficacité, récupérez votre mise en forme favorite à chaque création d'objet.
                        </Description>
                    </Info>
                </Column>
                <Column nbCol={3}>
                    <Info>
                        <Image alt="info sur la facilité de recherche" src="/images/search.svg"/>
                        <Title>
                            Recherche simplifiée
                        </Title>
                        <Description>
                            Retrouvez facilement vos objets sans perdre de temps
                        </Description>
                    </Info>
                </Column>
                <Column nbCol={3}>
                    <Info>
                        <Image alt="info sur la fin du regret" src="/images/smile.svg"/>
                        <Title>
                            Plus de regret
                        </Title>
                        <Description>
                            Fini la frustration de louper une occasion d'acheter pour compléter votre collection. Ne vous posez plus la question pour savoir si vous avez déjà l'objet convoité.
                        </Description>
                    </Info>
                </Column>
                <Column
                    customStyle={{
                        width: '100%'
                    }}
                    nbCol={3}>
                    <Info>
                        <Image alt="info sur la protection des collections" src="/images/protection.svg"/>
                        <Title>
                            Protégez-vous
                        </Title>
                        <Description>
                            En cas de sinistre, de vol ou autre ... soyez sûr de retrouver une liste de votre collection si besoin
                        </Description>
                    </Info>
                </Column>
            </Row>
        </Content>
    );
}

export default Infos;